<template>
  <div>
    <section id="banner" class="mt-3">
      <img src="@/assets/imagenes/banner1.svg" width="100%" alt="banner" />
    </section>
  </div>
</template>
<script>
export default {
  name: "BannerComponent"
};
</script>
