<template>
  <div>
    <section id="banner" class="mt-3">
      <img src="@/assets/imagenes/banner.svg" alt="banner1" class="banner1" />
    </section>
  </div>
</template>
<script>
export default {
  name: "Banner1Component"
};
</script>
